import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {navInit} from './scripts/navigation';
import {slickInit} from './scripts/slickInit';



fontawesome.library.add(far, fab, fas);

navInit();
slickInit();

jQuery(document).ready(function ( $ ) {
  // smooth scroll to ID
  $('.menu-item a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top - 70 // choose what you need
    }, 500, 'linear');
  });
});

jQuery(document).ready(function () {
  scroll_parallax();
  generateStars();
});
jQuery(window).on('scroll', function() {
  scroll_parallax();
});
jQuery(window).on('resize', function() {
  scroll_parallax();
});

function scroll_parallax() {
  var scrolled = jQuery(window).scrollTop();
  jQuery('.layer-top').css('transform', 'translateY(' + (-scrolled * 0.35) + 'px)');
  jQuery('.layer-middle').css('transform', 'translateY(' + (-scrolled * 0.15) + 'px)');
  jQuery('.layer-bottom').css('transform', 'translateY(' + (-scrolled * 0.05) + 'px)');
}

function generateStars()
{
  var cls = ['#4874ac', '#11459c', '#3d67b0'];
  addStarsToLayer('.layer-top', 25, [4, 8], [0.3, 0.7], cls);
  addStarsToLayer('.layer-middle', 50, [2, 3], [0.5, 1], cls);
  addStarsToLayer('.layer-bottom', 100, [1, 1.5], [0.8, 1], cls);
}
function addStarsToLayer(layer, count, sizeRange, opacityRange, colors)
{
  for (var i = 0; i < count; i++)
  {
    jQuery(layer).append(createStar(sizeRange, opacityRange, colors));
  }
}
function createStar(sizeRange, opacityRange, colors)
{
  var star = $('#star_template').clone().removeAttr('id');
  var size = getRandom(sizeRange[0], sizeRange[1]);
  var opacity = getRandomInt(opacityRange[0] * 100, opacityRange[1] * 100) / 100;
  var color = getRandomArrayItem(colors);
  var left = Math.random() < 0.6 ? (Math.random() < 0.5 ? getRandomInt(0, 20) : getRandomInt(80, 100)) + '%' : getRandomInt(20, 80) + '%';
  star.css({
    position: 'absolute',
    top: getRandomInt(0, 200) + '%',
    left: left,
    transform: 'scale('+size+')',
    fill: color,
    opacity: opacity
  });
  return star;
}
function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function getRandomArrayItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}
