import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

export function slickInit() {
  jQuery(document).ready(function ($) {
    $(".homesite_section_banner__slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      fade: true,
      dots: true,
    });


    $(".subsite_section_product_slider__blocks").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      // fade: true,
      dots: false,
      nextArrow: $('.subsite_section_product_slider__next'),
      prevArrow: $('.subsite_section_product_slider__prev'),
      responsive: [{

        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
  
      }, {
  
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,

        }
  
      }]
    });
  });
}